<template>
  <div>
    <div class="header-top">
      <img class="tp-img" src="../../assets/images/project/case-ban.png" />
      <div class="tp-title">技术应用（部分）</div>
      <div class="tp-ic"></div>
      <div class="tp-body"><div class="underline"></div></div>
      <div>
            <div v-if="openpanorama">
        <!-- 第一页内容区域 -->
        <div v-if="one" class="tp-content" >
          <div @click="goTo(item.id)"  class="middle" v-for="(item,id) in list1" :key="id">
          <img
              class="middle-img"
              :src="item.images"
            />
            <div class="middle-title">
             {{ item.name }}
            </div>
          </div>
        </div>
<!--          <div class="tp-content" v-if="two">-->
<!--          <div @click="skip18" class="middle">-->
<!--            <img-->
<!--              class="middle-img"-->
<!--              src="../../assets/images/project/7-1.png"-->
<!--            />-->
<!--            <div class="middle-title">余姚餐厨垃圾项目（改造）</div>-->
<!--          </div>-->
<!--        </div>-->
<!--     <div class="p-pagination">-->
<!--        <a @click="cut1"><</a>-->
<!--        <a @click="cut1" :style="TheStyle">1</a>-->
<!--        <a @click="cut2" :style="TheStyletwo">2</a>-->
<!--        <a @click="cut2">></a>-->
<!--      </div>-->
 <!-- 项目全景 -->
      <div v-if="closepanorama">
        <div  class="tp-content">
          <div @click="panorama1" class="middle">
            <img
              class="middle-img"
              src="../../assets/images/project/100-1.png"
            />
            <div class="middle-title">
              苏州餐厨垃圾项目(改造)
            </div>
          </div>

          <div @click="panorama2" class="middle">
            <img
              class="middle-img"
              src="../../assets/images/project/110-1.png"
            />

            <div class="middle-title">
              邯郸市大名县餐厨垃圾 PPP 项目
            </div>
          </div>

          <div @click="panorama3" class="middle">
            <img
              class="middle-img"
              src="../../assets/images/project/120-1.png"
            />
            <div class="middle-title">
              苏州厨余垃圾项目（改造）
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      one: true,
      two: false,
      openpanorama:true,
      closepanorama:false,
      list1: [
      {
        id: 101,
          name: "邯郸市大名县餐厨垃圾 PPP 项目",
          images: require("../../assets/images/project/110-1.png"),
        },
        {
          id: 102,
          name: "苏州厨余垃圾项目（改造）",
          images: require("../../assets/images/project/120-1.png"),
        },
        {
          id: 103,
          name: "武穴餐厨垃圾项目",
          images: require("../../assets/images/home/zh1.webp"),
        },
        {
          id: 105,
          name: "苏州腾康科技生态岛现代农业生态循环示范产业园",
          images: require("../../assets/images/solution/图片5.png"),
        },
        {
          id: 106,
          name: "射洪市餐厨垃圾处理项目",
          images: require("../../assets/images/project/1648888333.png"),
        },
        {
          id: 109,
          name: "亳州市餐厨废弃物处理项目",
          images: require("../../assets/images/project/bozhou666.webp"),
        },
        {
          id: 108,
          name: "昆明市餐厨垃圾处理项目（改造）",
          images: require("../../assets/images/project/202102041612430325601.webp"),
        },
        {
          id: 110,
          name: "徐州丰县餐厨垃圾项目",
          images: require("../../assets/images/project/6-1 (1).png"),
        },
        {
          id: 111,
          name: "陕西汉中餐厨废弃物处理项目建设中",
          images: require("../../assets/images/project/555.webp"),
        },
        {
          id: 113,
          name: "余姚餐厨垃圾项目（改造）",
          images: require("../../assets/images/project/7-1.png"),
        },
        {
          id: 112,
          name: "苏州餐厨垃圾项目(改造)",
          images: require("../../assets/images/project/100-1.png"),
        },

      ],


    };
  },
  methods: {
    cut1() {
      this.one = true;
      this.two = false;
    },
    cut2() {
      this.one = false;
      this.two = true;
    },
    goTo(e) {
      if(e=='101'){
        this.addskip2()
      }else if(e=='102'){
        this.addskip3()
      }else if(e=='103'){
        this.addskip4()
      }else if(e=='104'){
        this.addskip5()
      }else if(e=='105'){
        this.skip1()
      }else if(e=='106'){
        this.skip2()
      }else if(e=='107'){
        this.skip3()
      }else if(e=='108'){
        this.skip4()
      }else if(e=='109'){
        this.skip5()
      }else if(e=='110'){
        this.skip6()
      }else if(e=='111'){
        this.skip7()
      }else if(e=='112'){
        this.skip8()
      }else if(e=='113'){
        this.skip18()
      }
    },
  //   query(selector) {
  //    return Array.from(document.querySelectorAll(selector));
  // },
    panorama1(){
      this.$router.push({
        path: "./wuxuePlant",
      });
    },
    panorama2(){
    this.$router.push({
        path: "./suzhouPlant",
      });
    },
    panorama3(){
    this.$router.push({
        path: "./kunshanPlant",
      });
    },
    addskip2() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 101,
        },
      });
    },
    addskip3() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 102,
        },
      });
    },
    addskip4() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 103,
        },
      });
    },
    addskip5() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 104,
        },
      });
    },

    skip1() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 1,
        },
      });
    },
    skip2() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 2,
        },
      });
    },
    skip3() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 3,
        },
      });
    },
    skip4() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 4,
        },
      });
    },
    skip5() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 5,
        },
      });
    },
    skip6() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 6,
        },
      });
    },
    skip7() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 7,
        },
      });
    },
    skip8() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 8,
        },
      });
    },
    skip18() {
      this.$router.push({
        path: "./projectcaseInformation",
        query: {
          id: 71,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.middle {
  background:  #8cc6ee;
  width: 370px;
  height: 230px;
  margin-left: 30px;
  margin-top: 30px;
  overflow: hidden;
  .middle-img {
    width: 100%;
    height: 190px;
    transition: all 0.4s ease 0.1s;
  }
  .middle-img:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  .middle-title {
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
  }
}
.middle:hover span {
  display: block;
}
.ac span {
  display: none;
  text-decoration: none;
}
.examine {
  width: 140px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background:  #8cc6ee;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  position: absolute;
  margin-top: -100px;
  margin-left: 130px;
}
.p-pagination {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.p-paginations{
    width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.p-paginations a {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  padding: 0 10px;
  margin-right: 8px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
}
.p-pagination a {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  padding: 0 10px;
  margin-right: 8px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
}
.header-top {
  min-width: 1250px;
  height: 100%;
  margin: auto;
  .tp-title {
    text-align: center;
    margin-top: 35px;
    color: #1f1f1f;
    font-size: 31px;
  }
  .tp-ic {
    width: 40px;
    height: 4px;
    margin: 20px auto;
    background:  #8cc6ee;
  }
  .tp-content {
    width: 1250px;
    display: flex;
    flex-wrap: wrap;
    margin: 20px auto;
  }
}
.underline {
  width: 100%;
  height: 18px;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/zigongsi/i-title.png");
}
.tp-body {
  width: 560px;
  margin: auto;
}
.tp-img {
  height: 380px;
  width: 100%;
}

</style>
